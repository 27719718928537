import { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { alertActions } from "../../../actions/alert.actions";
import { BookingDetailsService, UserService } from "../../../services";
import { useLocation, useNavigate } from "react-router";
import {
  registration,
  updateUser,
  userLogin,
} from "../../../actions/UserAction";

const useBookingDetails = (t, locations, displayRazorpay) => {
  const location = useLocation();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const [otp1, setOtp1] = useState("");
  const [otp2, setOtp2] = useState("");
  const [otp3, setOtp3] = useState("");
  const [otp4, setOtp4] = useState("");
  const [otp5, setOtp5] = useState("");
  const otp1InputRef = useRef(null);
  const otp2InputRef = useRef(null);
  const otp3InputRef = useRef(null);
  const otp4InputRef = useRef(null);
  const otp5InputRef = useRef(null);
  // const dataPath = location.search.split("=")[1]?.substring(0, 24)
  const dispatch = useDispatch();
  const [bookInfo, setBookInfo] = useState([]);
  const [diffDate, setDiffDate] = useState(0);
  const [pageDisplay, setPageDisplay] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const navigate = useNavigate();
  const [userData, setUserData] = useState("notSet");
  const pathArray = window.location.pathname;
  const [bookid, setBookid] = useState([]);
  const [timeLeft, setTimeLeft] = useState(null);
  const [loader, setLoader] = useState("notset");
  const [pathimg, setPathimg] = useState();
  const [adultCountData, setAdultCountData] = useState("");

  var mydata = [];
  // const [backstate, setBackState] = useState(false);
  // console.log(location.search.split("=")[1]?.substring(0, 24))
  //   console.log(location?.state?.adultcount);
  /**
   * timer
   */
  useEffect(() => {
    if (timeLeft === 0) {
      setTimeLeft(null);
    }
    if (!timeLeft) return;
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  /**
   * get booking
   */
  useEffect(() => {
    var storedData = localStorage.getItem("adult_count");
    setAdultCountData(JSON.parse(storedData));
    if (mydata.length > 0) {
      setLoader("loading");
      BookingDetailsService.getBooking(mydata)
        .then((res) => {
          if (res?.data?.status?.code === 0) {
            setLoader("isfull");
            if (res?.data?.data?.[0]?.booking_status === 1) {
              // setBackState(true);
            } else {
              setLoader("isempty");
              // setBackState(false);

              navigate("/");
              // dispatch(alertActions.error("Amount paid"))
            }
            setBookInfo(res?.data?.data);
            setBookid([res?.data?.data?.[0]?._id]);
            // Number(
            //   data?.extra_occupancy *
            //     extracount *
            //     Number(
            //       diffDate && diffDate !== "1"
            //         ? Math.round(diffDate).toFixed(0)
            //         : 1
            //     )
            // ).toFixed(2)
            var total =
              Number(res?.data?.data?.[0]?.additional_charge) +
              Number(res?.data?.data?.[0]?.room_charge) +
              Number(res?.data?.data?.[0]?.tax_amount) -
              Number(res?.data?.data?.[0]?.discount_amount);
            setTotalAmount(total);

            localStorage.setItem("total", res?.data?.data?.[0]?.grand_total);
            // const date1 = (res?.data?.data?.[0]?.arrival_date);
            // const arr = date1.split("-");
            // const arr2 = new Date(arr[0], arr[1] - 1, arr[2]);
            // const date2 = (res?.data?.data?.[0]?.departure_date);
            // const arr3 = date2.split("-");
            // const arr4 = new Date(arr3[0], arr3[1] - 1, arr3[2]);
            // const diffInMs = Math.abs(arr4 - arr2);
            setDiffDate(location?.state?.date);
            localStorage.setItem("stay", location?.state?.date);
          }
        })
        .catch((err) => console.log(err));
    }
    // eslint-disable-next-line
  }, []);
  locations?.state?.data.map((data) => mydata.push(data?._id));

  /**
   * get booking
   */
  // const getBooking = () => {

  // }

  /**
   * handle change
   * @param {*} e
   */
  const handleChange = (e) => {
    //check name field and store its value
    if (e.target.name === "name") {
      if (/^[a-zA-Z ]*$/.test(e.target.value)) {
        setName(e.target.value);
      } else if (!e.target.value) {
        setName("");
      }
    }
    //check email and store
    if (e.target.name === "email") {
      setEmail(e.target.value);
    }
    //check phone and handle
    if (e.target.name === "phone") {
      setPhone(e.target.value);
    }
  };

  /**
   * send otp
   * @param {*} e
   */
  const sendOtp = (e) => {
    e.preventDefault();
    setSubmitted(true);

    var filter = /^[0-9]{10}$/;
    // eslint-disable-next-line
    var pattern =
      /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    // eslint-disable-next-line
    if (name && email && phone) {
      if (email && !pattern.test(email)) {
        dispatch(alertActions.error(t("Enter_valid_Email_Address")));
      } else if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        setLoader("loading");
        UserService.send_otp(name, email, Number(phone))
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setTimeLeft(59);
              setSubmitted(false);
              setShowOtp(true);
              setLoader("isfull");
              dispatch(alertActions.success(res.data.status.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(res.data.status.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  /**
   * make payment
   * @param {*} e
   */
  const makePayment = (e) => {
    e.preventDefault();
    // getBooking();
    setLoader("loading");
    mydata = [];
    locations?.state?.data.map((data) => mydata.push(data?._id));
    // if (backstate == true) {
    //     getBooking();
    BookingDetailsService.getBooking(mydata).then((res) => {
      if (res?.data?.status?.code === 0) {
        if (res?.data?.data?.[0]?.booking_status === 1) {
          setSubmitted(true);
          if (location?.state?.actualamt && bookid) {
            setLoader("loading");
            BookingDetailsService.makePayment(
              location?.state?.actualamt,
              mydata,
              locations?.state?.timediff,
              locations?.state?.diff,
              adultCountData
            )
              .then((res) => {
                if (res?.data?.status?.code === 0) {
                  setSubmitted(false);
                  displayRazorpay(res?.data, mydata);
                  setLoader("isfull");
                  // dispatch(alertActions.success(res.data.status.message));
                } else {
                  setLoader("isempty");
                  dispatch(alertActions.error(res.data.status.message));
                  console.log("ERROR MESSAGE", res.data.status.message);
                }
              })
              .catch((error) => {
                console.log("Errorreg", error);
              });
          } else {
            navigate("/");
          }
        }
      }
    });

    // }
  };

  /**
   * verify number
   * @param {*} e
   */
  const verifyNumber = (e) => {
    e.preventDefault();
    // setLoader("loading")
    setSubmitted(true);
    var filter = /^[0-9]{10}$/;
    if (phone) {
      if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        dispatch(updateUser(phone))
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setSubmitted(false);
              setUserData("full");
              setName(res?.data?.data?.user?.name);
              setEmail(res?.data?.data?.user?.email);
              setPhone(res?.data?.data?.user?.phone);
            } else {
              setSubmitted(false);
              setUserData("not-full");
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  /**
   * verify otp
   */
  const verifyOtpsubmit = () => {
    setLoader("loading");
    setSubmit(true);

    if (otp1 && otp2 && otp3 && otp4 && otp5 && phone && name && email) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(registration(name, email, phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            navigate("/");
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   *  verify mobile otp
   */
  const verifyMobileOtp = () => {
    setSubmit(true);
    if (otp1 && otp2 && otp3 && otp4 && otp5 && phone && name && email) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(registration(name, email, phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            // navigate(pathArray);
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };

  /**
   * login verify
   */
  const verifyOtpLoginsubmit = (e) => {
    e.preventDefault();
    setSubmit(true);
    if (otp1 && otp2 && otp3 && otp4 && otp5) {
      let otp = otp1 + otp2 + otp3 + otp4 + otp5;
      setLoader("loading");
      dispatch(userLogin(phone, otp))
        .then((response) => {
          if (response?.data?.status?.code === 0) {
            navigate("/");
            setSubmit(false);
            setLoader("isfull");
            dispatch(alertActions.success(response?.data?.status?.message));
          } else {
            setLoader("isempty");
            dispatch(alertActions.error(response?.data?.status?.message));
          }
        })
        .catch((error) => {
          console.log("Errorreg", error);
        });
    }
  };
  /**
   * store otp
   * @param {*} e
   */
  const storeotp = (e) => {
    if (e.target.name === "otp1") {
      setOtp1(e.target.value.slice(0, 1));
      otp2InputRef.current.focus();
    }
    if (e.target.name === "otp2") {
      setOtp2(e.target.value.slice(0, 1));
      otp3InputRef.current.focus();
    }
    if (e.target.name === "otp3") {
      setOtp3(e.target.value.slice(0, 1));
      otp4InputRef.current.focus();
    }
    if (e.target.name === "otp4") {
      setOtp4(e.target.value.slice(0, 1));
      otp5InputRef.current.focus();
    }
    if (e.target.name === "otp5") {
      setOtp5(e.target.value.slice(0, 1));
    }
  };

  /**
   * otp validation
   * @returns
   */
  const otp1valid = () => {
    if (otp1 === "") {
      return true;
    }
  };
  const otp2valid = () => {
    if (otp2 === "") {
      return true;
    }
  };
  const otp3valid = () => {
    if (otp3 === "") {
      return true;
    }
  };
  const otp4valid = () => {
    if (otp4 === "") {
      return true;
    }
  };
  const otp5valid = () => {
    if (otp5 === "") {
      return true;
    }
  };

  /**
   * resend otp
   */
  const resendOtp = () => {
    setTimeLeft(59);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");

    UserService.send_otp(name, email, phone)
      .then((res) => {
        setShowOtp(true);
        dispatch(alertActions.success(res.data.status.message));
      })
      .catch((error) => {
        console.log("Errorreg", error);
      });
  };
  const loginPage = (val) => {
    setPageDisplay(val);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    setShowOtp(false);
    setPhone("");
  };

  const selectPath = (data) => {
    setPathimg(data);
  };

  /**
   * send otp login
   */
  const sendLoginOtp = () => {
    setSubmitted(true);
    setOtp1("");
    setOtp2("");
    setOtp3("");
    setOtp4("");
    setOtp5("");
    var filter = /^[0-9]{10}$/;
    if (phone) {
      if (phone && !filter.test(phone)) {
        dispatch(alertActions.error(t("Enter_valid_Mobile_Number")));
      } else {
        setLoader("loading");
        UserService.send_otp_login(phone)
          .then((res) => {
            if (res?.data?.status?.code === 0) {
              setTimeLeft(59);
              setSubmitted(false);
              setShowOtp(true);
              setLoader("isfull");
              dispatch(alertActions.success(res.data.status.message));
            } else {
              setLoader("isempty");
              dispatch(alertActions.error(res.data.status.message));
            }
          })
          .catch((error) => {
            console.log("Errorreg", error);
          });
      }
    }
  };

  return {
    name,
    email,
    phone,
    submitted,
    showOtp,
    otp1,
    otp2,
    otp3,
    otp4,
    otp5,
    submit,
    otp1InputRef,
    otp2InputRef,
    otp3InputRef,
    otp4InputRef,
    otp5InputRef,
    bookInfo,
    diffDate,
    pageDisplay,
    totalAmount,
    userData,
    timeLeft,
    loader,
    pathimg,
    selectPath,
    makePayment,
    sendLoginOtp,
    handleChange,
    sendOtp,
    storeotp,
    otp1valid,
    otp2valid,
    otp3valid,
    otp4valid,
    otp5valid,
    verifyOtpsubmit,
    verifyOtpLoginsubmit,
    resendOtp,
    loginPage,
    verifyNumber,
    verifyMobileOtp,
  };
};
export default useBookingDetails;
